<template>
  <bb-input
    v-model="password"
    :name="name"
    :type="passwordInputType"
    :placeholder="placeholder"
    autocomplete="password"
    class="mb-16"
  >
    <template #label>
      <span v-text="label" />
      <span v-if="required" class="ml-4 text-red-500">*</span>
    </template>
    <template #suffix>
      <fa-icon :icon="['fas', showPasswordIcon]" class="cursor-pointer text-gray-400" @click="toggleShowPassword" />
    </template>
  </bb-input>
</template>

<script setup lang="ts">
const props = defineProps<{
  modelValue: string;
  placeholder?: string;
  label: string;
  required?: boolean;
  name: string;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

const password = useVModel(props, 'modelValue', emit);

const showPassword = ref(false);

const showPasswordIcon = computed(() => (showPassword.value ? 'eye-slash' : 'eye'));
const passwordInputType = computed(() => (showPassword.value ? 'text' : 'password'));

const toggleShowPassword = () => {
  showPassword.value = !showPassword.value;
};
</script>
